import React from 'react'

interface DiscordIconProps {
  className?: string
}

export const DiscordIcon: React.FC<DiscordIconProps> = ({ className = '' }) => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M24.6702 8.54751C23.0933 7.83313 21.4287 7.32632 19.7188 7.04004C19.4848 7.45396 19.2731 7.87984 19.0845 8.31588C17.2631 8.04428 15.4109 8.04428 13.5895 8.31588C13.4008 7.87988 13.1891 7.45401 12.9552 7.04004C11.2442 7.32874 9.57846 7.83675 8.00004 8.55125C4.86649 13.139 4.01703 17.6129 4.44176 22.0232C6.27685 23.3649 8.33084 24.3853 10.5144 25.04C11.0061 24.3856 11.4412 23.6914 11.8151 22.9647C11.105 22.7023 10.4196 22.3785 9.76692 21.9971C9.93869 21.8738 10.1067 21.7468 10.269 21.6235C12.1682 22.5073 14.2411 22.9656 16.3398 22.9656C18.4386 22.9656 20.5114 22.5073 22.4106 21.6235C22.5748 21.7561 22.7428 21.8831 22.9127 21.9971C22.2588 22.3791 21.5722 22.7035 20.8608 22.9666C21.2342 23.6929 21.6693 24.3866 22.1614 25.04C24.3469 24.3879 26.4025 23.368 28.2379 22.0251C28.7362 16.9105 27.3865 12.4778 24.6702 8.54751ZM12.353 19.3109C11.1695 19.3109 10.1916 18.248 10.1916 16.9404C10.1916 15.6328 11.1355 14.5606 12.3493 14.5606C13.563 14.5606 14.5333 15.6328 14.5126 16.9404C14.4918 18.248 13.5593 19.3109 12.353 19.3109ZM20.3266 19.3109C19.1411 19.3109 18.1671 18.248 18.1671 16.9404C18.1671 15.6328 19.1109 14.5606 20.3266 14.5606C21.5423 14.5606 22.505 15.6328 22.4842 16.9404C22.4635 18.248 21.5328 19.3109 20.3266 19.3109Z" />
    </svg>
  )
}
