'use client'

import { useEffect, useState } from 'react'

const animateBaseAttributes = {
  fill: 'freeze',
  calcMode: 'spline',
  keyTimes: '0; 1',
  keySplines: '.2 0 .2 1',
}

export const Animate = ({ parentId }: { parentId: string }) => {
  const [begin, setBegin] = useState('')

  useEffect(
    () =>
      setBegin(`cortexLogo.click; ${parentId}.mouseenter; ${Math.random()}s`),
    [parentId]
  )

  const colorPath = /Mark[RGB]$/.exec(parentId)

  return (
    begin && (
      <>
        <animate
          attributeName="stroke-dashoffset"
          values="1; 0"
          begin={begin}
          dur="1.5s"
          {...animateBaseAttributes}
        />
        <animate
          attributeName="stroke-opacity"
          values={`.9; ${colorPath ? 1 : 0}`}
          begin={begin}
          dur="2s"
          {...animateBaseAttributes}
        />
        <animate
          attributeName="fill-opacity"
          values=".1; 1"
          begin={begin}
          dur="3s"
          {...animateBaseAttributes}
        />
      </>
    )
  )
}
