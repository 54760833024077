import { SVGProps } from 'react'

import { Animate } from '@/components/ui/CortexLogoAnimateTags'

const square: 'square' | 'butt' | 'round' | 'inherit' | undefined = 'square'

const pathBaseAttributes = {
  strokeOpacity: 0,
  fillOpacity: 0.1,
  pathLength: 1,
  strokeDasharray: 1,
  strokeDashoffset: 1,
  strokeLinecap: square,
}

export const CortexLogoAnimate = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      id="cortexLogo"
      width="192"
      height="48"
      viewBox="0 0 384 96"
      preserveAspectRatio="xMinYMid"
      {...props}
    >
      <g strokeWidth="4" strokeOpacity=".1" fill="none">
        <path d="m2 2 h92 v92 h-92z" stroke="#FF6633" />
        <path d="m8 8 h80 v80 h-80z" stroke="#00E500" />
        <path d="m14 14 h68 v68 h-68z" stroke="#1A8CFF" />
      </g>
      <g strokeWidth="4" fill="none">
        <path
          id="cortexMarkR"
          d="m2 2 h92 v92 h-92z"
          {...pathBaseAttributes}
          stroke="#FF6633"
          strokeOpacity="1"
        >
          <Animate parentId="cortexMarkR" />
        </path>
        <path
          id="cortexMarkG"
          d="m8 8 h80 v80 h-80z"
          {...pathBaseAttributes}
          stroke="#00E500"
          strokeOpacity="1"
        >
          <Animate parentId="cortexMarkG" />
        </path>
        <path
          id="cortexMarkB"
          d="m14 14 h68 v68 h-68z"
          {...pathBaseAttributes}
          stroke="#1A8CFF"
          strokeOpacity="1"
        >
          <Animate parentId="cortexMarkB" />
        </path>
      </g>

      <path
        id="cortexMarkX"
        d="M42.9 27.9004C47.4 30 49.5 35 51.2 40.6C53.9536 36.9676 57.9 27.9 64.7 27.9C69.0096 27.9 69.5 30.234 69.5 31.3038C69.5 32.3 68.8 35.4578 65.5606 35.4578C63.6209 35.4578 63.0002 34.6748 61.7326 34.6748C58.4 34.6748 57.9 37.2 52.7337 44.9135C55 49.6 57.3 61.5 60.7 61.5C61.5114 61.5 63.6 61 65.8 56.3L67.9338 57.8258C66.4 59.6 62.7 68.0828 57.2 68.0828C52.2 68.0828 51 63.0188 47.2466 52.2C41.5 60.5 38.7 68.1 31.7002 68.1C27.8 68.1 26.7002 65.1 26.7002 63.5C26.7002 61.8942 27.9989 59.7748 30.4996 59.7748C33.0002 59.7748 32.6884 61.5 34.6 61.5C37.6 61.5 41 55.5 45.8 48C44 43.5 43.6 41.2 41.5 37C39.4 32.8 35 32 31.2395 33.5647L30.8005 30.9L42.9 27.9004Z"
        {...pathBaseAttributes}
      >
        <Animate parentId="cortexMarkX" />
      </path>
      <g>
        <path
          id="cortexLogoC"
          d="M168.532 61.5481C168.028 64.9081 167.188 70.9561 167.02 73.8121L166.432 74.4841C161.14 76.6681 154.756 78.0121 147.7 78.0121C129.892 78.0121 114.436 66.1681 114.436 47.8561C114.436 28.8721 129.808 18.0361 147.196 18.0361C153.16 18.0361 160.216 19.1281 166.012 21.3121C166.099 21.3995 166.197 21.4768 166.29 21.5509C166.467 21.6907 166.629 21.8195 166.684 21.9841C166.684 24.4607 167.161 28.8446 167.512 32.0732C167.578 32.6765 167.639 33.2416 167.692 33.7441C167.603 33.7664 167.502 33.8004 167.39 33.8385C167.077 33.944 166.674 34.0801 166.18 34.0801C165.424 34.0801 165.256 34.0801 165.004 33.3241C162.652 24.3361 154.504 21.7321 147.868 21.7321C133.84 21.7321 126.112 31.8121 126.112 45.5881C126.112 61.5481 135.352 74.2321 150.136 74.2321C158.704 74.2321 163.408 69.7801 165.676 61.9681C165.928 61.2961 166.264 61.2961 166.936 61.2961C167.449 61.2961 167.865 61.3942 168.219 61.4779C168.329 61.5038 168.433 61.5283 168.532 61.5481Z"
          {...pathBaseAttributes}
        >
          <Animate parentId="cortexLogoC" />
        </path>
        <path
          id="cortexLogoO"
          d="M283.541 71.9954C283.424 71.7799 283.295 71.5415 283.129 71.3758C281.701 72.2158 279.433 72.7198 277.501 72.7198C273.049 72.7198 271.033 71.1238 271.033 66.0838V44.6638C271.117 44.4118 271.285 44.2438 271.537 44.0758H282.373C282.961 43.8238 282.961 43.0678 282.961 41.6398C282.961 40.0438 282.961 39.6238 282.373 39.3718H271.621C271.285 39.1198 271.117 38.9518 271.033 38.7838C271.033 36.6838 271.117 33.1558 271.201 30.8038C270.865 30.4678 270.445 30.2998 270.109 30.2998C269.773 30.2998 269.521 30.3838 269.269 30.7198C266.833 33.7438 262.297 38.7838 257.341 42.6478C257.341 42.7598 257.416 43.0211 257.49 43.2825C257.528 43.4131 257.565 43.5438 257.593 43.6558C257.761 43.9918 258.013 44.0758 258.433 44.0758H261.037C261.625 44.0758 261.961 44.3278 261.961 46.2598V66.4198C261.961 73.6438 263.977 78.0118 271.873 78.0118C275.821 78.0118 279.937 76.4158 282.793 74.3998C283.801 73.7278 283.969 73.0558 283.717 72.2998C283.66 72.2135 283.602 72.1076 283.541 71.9954Z"
          {...pathBaseAttributes}
        >
          <Animate parentId="cortexLogoO" />
        </path>
        <path
          id="cortexLogoR"
          d="M321.084 68.5199C320.748 68.2679 320.328 68.0159 320.076 67.9319C318.06 70.0319 314.784 71.7959 311.592 71.7959C302.688 71.7959 296.724 63.5639 296.724 55.5839C296.724 54.9119 296.808 54.0719 296.892 53.6519L297.396 52.9799H321.42C321.924 52.5599 322.008 52.3079 322.092 51.5519C321.588 42.3119 315.54 37.8599 306.888 37.8599C296.976 37.8599 288.66 46.4279 288.66 58.0199C288.66 68.6879 295.212 78.0119 306.468 78.0119C312.432 78.0119 317.304 75.4079 321 70.8719C322.008 69.6959 321.84 69.2759 321.084 68.5199ZM297.732 49.2839C298.824 43.9919 301.932 40.8839 306.3 40.8839C310.08 40.8839 312.936 43.7399 312.852 48.7799C312.768 49.1159 312.6 49.3679 312.348 49.6199L298.152 49.8719C297.9 49.7879 297.816 49.5359 297.732 49.2839Z"
          {...pathBaseAttributes}
        >
          <Animate parentId="cortexLogoR" />
        </path>
        <path
          id="cortexLogoT"
          d="M237.589 76.6343C235.239 76.5686 232.782 76.5 231.422 76.5C229.98 76.5 227.968 76.5683 226.039 76.6337C224.262 76.694 222.554 76.752 221.426 76.752C221.342 76.332 221.258 75.912 221.258 75.324C221.258 74.736 221.426 74.4 222.014 74.316L224.702 73.896C226.634 73.56 226.634 71.964 226.634 66.84V48.444C226.634 46.512 226.55 46.176 225.626 45.588L222.686 43.992C222.647 43.9698 222.611 43.9491 222.576 43.9295C222.088 43.653 222.014 43.6116 222.014 42.984C222.014 42.7871 222.043 42.5327 222.067 42.3219C222.084 42.1728 222.098 42.0456 222.098 41.976C223.862 41.556 225.794 41.052 228.65 40.044C230.081 39.4878 231.191 38.8376 232.098 38.3068C233.109 37.7153 233.866 37.272 234.53 37.272C234.866 37.272 235.034 37.356 235.202 37.524L235.454 43.824C235.538 43.908 235.79 43.992 236.126 43.992C236.265 43.8571 236.404 43.7217 236.543 43.5861C239.432 40.7658 242.409 37.86 247.298 37.86C249.566 37.86 251.666 38.784 251.666 41.22C251.666 43.572 250.238 47.688 247.718 47.688C246.507 47.688 245.625 47.0085 244.71 46.3029C243.721 45.5407 242.693 44.748 241.166 44.748C239.486 44.748 237.89 45.756 236.546 47.772C235.874 48.528 235.79 49.368 235.79 50.544V66.84C235.79 71.964 236.042 73.392 237.974 73.644L242.342 74.232C242.846 74.316 243.014 74.652 243.014 75.324C243.014 75.9118 242.93 76.3318 242.846 76.7516C241.802 76.7516 239.741 76.6944 237.589 76.6343Z"
          {...pathBaseAttributes}
        >
          <Animate parentId="cortexLogoT" />
        </path>
        <path
          id="cortexLogoE"
          d="M196.319 78.0119C208.583 78.0119 216.563 69.1919 216.563 57.6839C216.563 45.7559 207.743 37.8599 195.563 37.8599C183.299 37.8599 175.319 46.5959 175.319 58.1879C175.319 70.0319 184.139 78.0119 196.319 78.0119ZM195.647 41.1359C203.039 41.1359 206.315 50.5439 206.315 58.0199C206.315 65.7479 204.551 74.7359 196.319 74.7359C188.927 74.7359 185.651 65.2439 185.651 57.8519C185.651 50.1239 187.415 41.1359 195.647 41.1359Z"
          {...pathBaseAttributes}
        >
          <Animate parentId="cortexLogoE" />
        </path>
        <path
          id="cortexLogoX"
          d="M42.9 27.9004C47.4 30 49.5 35 51.2 40.6C53.9536 36.9676 57.9 27.9 64.7 27.9C69.0096 27.9 69.5 30.234 69.5 31.3038C69.5 32.3 68.8 35.4578 65.5606 35.4578C63.6209 35.4578 63.0002 34.6748 61.7326 34.6748C58.4 34.6748 57.9 37.2 52.7337 44.9135C55 49.6 57.3 61.5 60.7 61.5C61.5114 61.5 63.6 61 65.8 56.3L67.9338 57.8258C66.4 59.6 62.7 68.0828 57.2 68.0828C52.2 68.0828 51 63.0188 47.2466 52.2C41.5 60.5 38.7 68.1 31.7002 68.1C27.8 68.1 26.7002 65.1 26.7002 63.5C26.7002 61.8942 27.9989 59.7748 30.4996 59.7748C33.0002 59.7748 32.6884 61.5 34.6 61.5C37.6 61.5 41 55.5 45.8 48C44 43.5 43.6 41.2 41.5 37C39.4 32.8 35 32 31.2395 33.5647L30.8005 30.9L42.9 27.9004Z"
          transform="translate(298.6716, 9.7936)"
          {...pathBaseAttributes}
        >
          <Animate parentId="cortexLogoX" />
        </path>
      </g>
    </svg>
  )
}
