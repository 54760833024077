'use client'

import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Pane } from '@/components/ui/Pane'
import { windowMetadata } from '@/constants/windowMetadata'
import {
  removeSelectedOption,
  setSelectedAndOverwrite,
} from '@/store/slices/selectedOptionsSlice'
import { RootState } from '@/store'

interface SelectedOptionsProps {}

export const SelectedOptions: React.FC<SelectedOptionsProps> = ({}) => {
  const dispatch = useDispatch()
  const panePositions = useRef<{ [key: string]: { x: number; y: number } }>({})
  const { selected, selectedOptions } = useSelector(
    (state: RootState) => state.selectedOptions
  )

  const renderPane = (option: string, index: number) => {
    const paneData = windowMetadata.find((item) => item.key === option)
    if (!paneData) {
      return null
    }

    if (!panePositions.current[option]) {
      panePositions.current[option] = {
        x: 20 * index,
        y: 60 * index,
      }
    }

    const optionIndex = windowMetadata.indexOf(paneData)
    const previousOption = windowMetadata[optionIndex - 1]
    const nextOption = windowMetadata[optionIndex + 1]

    const paneProps = {
      isFirstOption: optionIndex === 0,
      isLastOption: optionIndex === windowMetadata.length - 1,
      onSelect: () => dispatch(setSelectedAndOverwrite(option)),
      onSelectNext: () => dispatch(setSelectedAndOverwrite(nextOption.key)),
      onSelectPrevious: () =>
        dispatch(setSelectedAndOverwrite(previousOption.key)),
      onRemove: () => dispatch(removeSelectedOption(option)),
      isSelected: selected === option,
      initialPosition: panePositions.current[option],
    }

    return (
      <Pane
        key={option}
        header={paneData.title}
        content={paneData.content}
        id={option}
        {...paneProps}
      />
    )
  }

  return (
    <>
      {selectedOptions.map((option, index) => (
        <React.Fragment key={option.id}>
          {renderPane(option.id, index)}
        </React.Fragment>
      ))}
    </>
  )
}
