import { CortexLogoAnimate } from './ui/CortexLogoAnimate'
import { SocialIcons } from './SocialIconsList'

export const LogoFooter = () => {
  return (
    <footer className="flex justify-between sm:max-w-sm items-center lg:absolute bottom-8 w-full">
      <CortexLogoAnimate className="stroke-cortex-strong fill-cortex-strong h-10 sm:h-12" />
      <SocialIcons />
    </footer>
  )
}
