'use client'

import React, { useEffect, useRef, useState } from 'react'

import { InteractiveTerminal } from '@/components/InteractiveTerminal'
import { useTerminalState } from '@/hooks/useTerminalState'

export const TerminalDisplay = () => {
  const {
    displayedLines,
    currentLine,
    animationState,
    isInitialLinesComplete,
  } = useTerminalState()

  const [isTerminalActive, setIsTerminalActive] = useState(true)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      const clickedElement = event.target as HTMLElement

      const paneDiv = clickedElement.closest('.pane')?.querySelector('div')

      if (paneDiv) {
        setIsTerminalActive(false)
        return
      }

      if (inputRef.current) {
        setIsTerminalActive(true)
        if (window.innerWidth >= 768) {
          inputRef.current.focus()
        }
      }
    }

    document.addEventListener('click', handleDocumentClick)

    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])

  return (
    <InteractiveTerminal
      displayedLines={displayedLines}
      currentLine={currentLine}
      animationState={animationState}
      isInitialLinesComplete={isInitialLinesComplete}
      inputRef={inputRef}
      isTerminalActive={isTerminalActive}
    />
  )
}
