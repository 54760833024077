import React from 'react'

interface TelegramIconProps {
  className?: string
}

export const TelegramIcon: React.FC<TelegramIconProps> = ({
  className = '',
}) => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_4047_8553)">
        <path d="M13.2384 18.8842L12.8785 23.9471C13.3935 23.9471 13.6165 23.7258 13.884 23.4602L16.2984 21.1527L21.3014 24.8166C22.219 25.3279 22.8654 25.0586 23.1129 23.9725L26.3969 8.58451L26.3978 8.58361C26.6888 7.22723 25.9073 6.69683 25.0133 7.02958L5.71038 14.4198C4.393 14.9312 4.41294 15.6656 5.48644 15.9983L10.4214 17.5333L21.8844 10.3607C22.4239 10.0034 22.9144 10.2011 22.5109 10.5583L13.2384 18.8842Z" />
      </g>
      <defs>
        <clipPath id="clip0_4047_8553">
          <rect
            width="21.76"
            height="21.76"
            transform="translate(4.7002 5.12012)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
