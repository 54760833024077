import Image from 'next/image'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { InteractiveInput } from '@/components/InteractiveInput'
import { RootState } from '@/store'
import { removeSelectedOption } from '@/store/slices/selectedOptionsSlice'
import CortexLogo from '@/assets/cortex-logo.svg'

export interface InteractiveTerminalProps {
  displayedLines: string[]
  currentLine: string
  animationState: 'initial' | 'typing' | 'complete' | 'removing'
  isInitialLinesComplete: boolean
  inputRef: React.RefObject<HTMLInputElement>
  isTerminalActive: boolean
}

export const InteractiveTerminal: React.FC<InteractiveTerminalProps> = ({
  displayedLines,
  currentLine,
  animationState,
  isInitialLinesComplete,
  inputRef,
  isTerminalActive,
}) => {
  const [showHeader, setShowHeader] = useState(false)
  const { loadTerminalImmediately } = useSelector(
    (state: RootState) => state.terminal
  )
  const { selected } = useSelector((state: RootState) => state.selectedOptions)

  const dispatch = useDispatch()

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowHeader(true)
    }, 500)

    return () => clearTimeout(timer)
  }, [])

  const containerClass = [
    'sm:max-xl:max-w-lg h-fit overflow-y-clip',
    'bg-cortex-tint shadow-lg',
    'border rounded-md',
    'font-mono dark:text-cortex-yellow',
    isTerminalActive ? 'dark:border-white/10' : 'dark:border-white/10',
    loadTerminalImmediately
      ? 'opacity-100'
      : `${animationState === 'initial' ? 'opacity-0' : 'opacity-100'} transition-opacity duration-[800ms]`,
  ].join(' ')

  const lineContainerClass = [
    'grid grid-cols-[auto_1fr] gap-2 gap-x-[1ch] content-end m-3 transition-all duration-[667ms] ease-out',
    animationState === 'removing' ? 'mt-0 max-h-0' : 'max-h-72',
  ].join(' ')

  const lineClass =
    'dark:text-cortex-yellow before:content-[">"] grid grid-cols-subgrid col-span-full'

  const headerClass = [
    'flex gap-2 p-2 px-3 bg-cortex-platform rounded-t-md items-start',
    'border-b dark:border-white/10',
    'relative z-10',
    'hover:cursor-pointer',
    loadTerminalImmediately
      ? 'opacity-100'
      : `transition-all duration-500 ease-in-out ${
          showHeader
            ? 'opacity-100 translate-y-0'
            : 'opacity-0 -translate-y-full'
        }`,
  ].join(' ')

  const navContainerClass = [
    'm-3 ',
    loadTerminalImmediately || 'transition-opacity duration-[800ms]',
    loadTerminalImmediately ||
      isInitialLinesComplete ||
      'h-0 opacity-0 overflow-clip',
  ]
    .filter(Boolean)
    .join(' ')

  return (
    <article className={containerClass}>
      <header
        className={headerClass}
        onClick={() => dispatch(removeSelectedOption(selected))}
      >
        <Image
          src={CortexLogo}
          height={16}
          width={16}
          alt="Cortex logo"
          className="pt-px sm:pt-0.5 mt-0.5"
        />
        <h1 className="text-[0.95em]">Cortex L1 &mdash; Secured by $SYN</h1>
      </header>
      {loadTerminalImmediately || (
        <ul className={lineContainerClass}>
          {displayedLines.map((line, index) => (
            <li key={index} className={lineClass}>
              {line}
            </li>
          ))}
          {currentLine && <li className={lineClass}>{currentLine}</li>}
        </ul>
      )}
      <nav className={navContainerClass}>
        <InteractiveInput inputRef={inputRef} />
      </nav>
    </article>
  )
}
