'use client'

import React, { useEffect } from 'react'

import { AccountsOverview } from '@/components/AccountsOverview'
import { LogoFooter } from '@/components/LogoFooter'
import { SelectedOptions } from '@/components/SelectedOptions'
import { TerminalDisplay } from '@/components/TerminalDisplay'

const Home = () => {
  useEffect(() => {
    // Function to get query parameters from the URL
    const getQueryParam = (param: any) => {
      const urlParams = new URLSearchParams(window.location.search)
      return urlParams.get(param)
    }

    // Check if the session-token exists
    const sessionToken = getQueryParam('session-token')
    // console.log(sessionToken)

    if (sessionToken) {
      // Store session-token in local storage
      localStorage.setItem('session-token', sessionToken)

      // Close the tab
      window.close()
    }
  }, [])

  return (
    <main className="relative min-h-dvh p-2 pb-4 sm:p-4 2xl:p-8 flex flex-col gap-2 gap-4 transition-[padding]">
      <div className="grow grid grid-rows-[1fr_auto] xl:grid-cols-[1fr_auto] max-sm:flex-col sm:flex-wrap gap-4 sm:content-between">
        <div className="grow grid grid-rows-[auto_1fr] lg:grid-cols-2 gap-4 max-w-5xl sm:h-fit">
          <TerminalDisplay />
          <SelectedOptions />
        </div>
        <div className="sm:grow flex place-content-end">
          <AccountsOverview />
        </div>
      </div>
      <LogoFooter />
    </main>
  )
}

export default Home
