import React from 'react'

interface MediumIconProps {
  className?: string
}

export const MediumIcon: React.FC<MediumIconProps> = ({ className = '' }) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="currentcolor"
      className={className}
    >
      <path d="M39.1998 11.4633L39.2376 11.455V11.1616H31.5054L24.3258 28.0362L17.1461 11.1618H8.81385V11.455L8.85117 11.4633C10.2625 11.7819 10.9792 12.257 10.9792 13.9701V34.0278C10.9792 35.7411 10.2598 36.2162 8.84835 36.5348L8.81104 36.5431V36.8372H14.4643V36.5441L14.4268 36.5357C13.0155 36.2173 12.299 35.742 12.299 34.0289V15.1336L21.522 36.8372H22.0451L31.5368 14.5284V34.5242C31.4158 35.8774 30.7058 36.2952 29.4277 36.5837L29.3899 36.5925V36.8833H39.2376V36.5925L39.1998 36.5837C37.9203 36.2952 37.1932 35.8774 37.0723 34.5242L37.0658 13.9701H37.0723C37.0723 12.257 37.7888 11.7819 39.1998 11.4633Z" />
    </svg>
  )
}
