export const normalizeForAlias = (value: string): string => {
  const lowerValue = value.toLowerCase()

  if (lowerValue === '1') return '01'
  if (lowerValue === 'ac') return '01-Ac'
  if (lowerValue === '2') return '02'
  if (lowerValue === 'ar') return '02-Ar'
  if (lowerValue === '3') return '03'
  if (lowerValue === 'ai') return '03-AI'
  if (lowerValue === '4') return '04'
  if (lowerValue === 'po') return '04-Po'

  return value
}
